import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import SideBarView from "../components/SideBarView"
import { Table } from "antd"
import { Grid } from "@mui/material"
// import dateFormat from "dateformat"


const tableData = [
  {
    "year": "1800",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1801",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1802",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1803",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1804",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1805",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1806",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1807",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1808",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1809",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1810",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1811",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1812",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1813",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1814",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1815",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1816",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1817",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1818",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1819",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1820",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1821",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1822",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1823",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1824",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1825",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1826",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1827",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1828",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1829",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1830",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1831",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1832",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1833",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1834",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1835",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1836",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1837",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1838",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1839",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1840",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1841",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1842",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1843",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1844",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1845",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1846",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1847",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1848",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1849",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1850",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1851",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1852",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1853",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1854",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1855",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1856",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1857",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1858",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1859",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1860",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1861",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1862",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1863",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1864",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1865",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1866",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1867",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1868",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1869",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1870",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1871",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1872",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1873",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1874",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1875",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1876",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1877",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1878",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1879",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1880",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1881",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1882",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1883",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1884",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1885",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1886",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1887",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1888",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1889",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1890",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1891",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1892",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1893",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1894",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1895",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1896",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1897",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1898",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1899",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1900",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1901",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1902",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1903",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1904",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1905",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1906",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1907",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1908",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1909",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1910",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1911",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1912",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1913",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1914",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1915",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1916",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1917",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1918",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1919",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1920",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1921",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1922",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1923",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1924",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1925",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1926",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1927",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1928",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1929",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1930",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1931",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1932",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1933",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1934",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1935",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1936",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1937",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1938",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1939",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1940",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1941",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1942",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1943",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1944",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1945",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1946",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1947",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1948",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1949",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1950",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1951",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1952",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1953",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1954",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1955",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1956",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1957",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1958",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1959",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1960",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1961",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1962",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1963",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1964",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1965",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1966",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1967",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1968",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1969",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1970",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1971",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1972",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1973",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1974",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1975",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1976",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1977",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1978",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1979",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1980",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1981",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1982",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1983",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1984",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1985",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1986",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1987",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1988",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1989",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1990",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1991",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1992",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1993",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1994",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1995",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "1996",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "1997",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "1998",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "1999",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2000",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2001",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2002",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2003",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2004",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2005",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2006",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2007",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2008",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2009",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2010",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2011",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2012",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2013",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2014",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2015",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2016",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2017",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2018",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2019",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2020",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2021",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2022",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2023",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2024",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2025",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2026",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2027",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2028",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2029",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2030",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2031",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2032",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2033",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2034",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2035",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2036",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2037",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2038",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2039",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2040",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2041",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2042",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2043",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2044",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2045",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2046",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2047",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2048",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2049",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2050",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2051",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2052",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2053",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2054",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2055",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2056",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2057",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2058",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2059",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2060",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2061",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2062",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2063",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2064",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2065",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2066",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2067",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2068",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2069",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2070",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2071",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2072",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2073",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2074",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2075",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2076",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2077",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2078",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2079",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2080",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2081",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2082",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2083",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2084",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2085",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2086",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2087",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2088",
    "daysCount": "366",
    "weeksCount": "53",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2089",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2090",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2091",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2092",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2093",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2094",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2095",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2096",
    "daysCount": "366",
    "weeksCount": "52",
    "isLeapYear": "Yes",
    "leapYearCycle": "Year IV"
  },
  {
    "year": "2097",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year I"
  },
  {
    "year": "2098",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year II"
  },
  {
    "year": "2099",
    "daysCount": "365",
    "weeksCount": "53",
    "isLeapYear": "",
    "leapYearCycle": "Year III"
  },
  {
    "year": "2100",
    "daysCount": "365",
    "weeksCount": "52",
    "isLeapYear": "",
    "leapYearCycle": "Year IV"
  }
]

const YearsPage = () => {

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      sorter: (a, b) => a.year - b.year,
      fixed: "left",
      width: "100px"
    },
    {
      title: "Days Count",
      dataIndex: "daysCount",
      key: "daysCount",
      width: "150px"
    },
    {
      title: "Weeks Count",
      dataIndex: "weeksCount",
      key: "weeksCount",
      width: "150px"
    },
    {
      title: "Is Leap Year",
      dataIndex: "isLeapYear",
      key: "isLeapYear",
      width: "150px"
    },
    {
      title: "Leap Year Cycle",
      dataIndex: "leapYearCycle",
      key: "leapYearCycle",
      width: "150px"
    }
  ]

  return (
    <Container>
      <CustomHeader title={`Years, Leap Years, Number of Days and Weeks in a Year`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <div style={{ marginTop: "20px" }}>
            <p>
              <b>ISO weeks:</b> Number of weeks according to ISO-8601 (weeks starting on Monday). The first week is the
              week with a Thursday in the new year (first 4-day week).<br/>
              <b>Leap years:</b> Every year that is divisible by four is a leap year, except for years that are
              divisible by 100, but not by 400.<br/>
              <br/>
            </p>

          </div>
          <h2
            style={{
              color: "#4d3267",
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif"
            }}
          >
            List of years 1800-2100
          </h2>
          <div>
            <Table scroll={{ x: "100%" }} width={"100%"} dataSource={tableData} columns={columns} rowKey={"year"}
                   bordered size="small" pagination={false} sticky/>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default YearsPage

export const Head = () => {
  const siteMetadata = {
    description: "List of years from 1900 to 2100 with number days and the leap year cycle",
    description2: "",
    keywordsContent: "year, leap year cycle, 1900, 2100, is leap year"
  }
  return (
    <Seo
      title="Epoch Converter - List of years from 1900 to 2100 (including leap years)"
      canonicalUrl={`https://www.epochconverter.io/years/`}
      siteMetadata={siteMetadata}
    />
  )
}
